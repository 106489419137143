import {computed, ref} from 'vue';
import {
  setCompanyNotificationsConfiguration,
  EMAIL_CHANNEL,
  WHATSAPP_CHANNEL,
  VIA_EMAIL, VIA_WHATSAPP, fetchCompanyNotificationsConfiguration
} from '@/Company/Configuration/services';

export function useCompanyConfig(translations) {

  const notificationsConfig = ref([
    {
      id: VIA_EMAIL,
      name: translations[VIA_EMAIL],
      value: false,
    },
    {
      id: VIA_WHATSAPP,
      name: translations[VIA_WHATSAPP],
      value: false,
    }
  ]);


  const isNetworkError = ref(false);

  const successSetCompanyConfig = ref(false);
  const isSendingSetCompanyConfig = ref(false);

  const viaEmailEnabled = computed(() => {

    return notificationsConfig.value.find((config) => config.id === VIA_EMAIL).value;
  })

  const viaWhatsappEnabled = computed(() => {
    return notificationsConfig.value.find((config) => config.id === VIA_WHATSAPP).value;
  })

  const notificationsConfigEnabled = computed(() => {
    return viaEmailEnabled.value || viaWhatsappEnabled.value;
  })

  const setCompanyConfig = async (company, administrator) => {
    isNetworkError.value = false;
    successSetCompanyConfig.value = false;
    isSendingSetCompanyConfig.value = true;


    try {
      const params = {
        isEnabled: notificationsConfigEnabled.value,
        channels: [
          ...(viaEmailEnabled.value ? [EMAIL_CHANNEL] : []),
          ...(viaWhatsappEnabled.value ? [WHATSAPP_CHANNEL] : []),
        ],
        receptionists: [],
        administrator: administrator,
        companyName: company.name
      };

      await setCompanyNotificationsConfiguration(company.id, params);
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isSendingSetCompanyConfig.value = false;
    }
  };

  const getCompanyConfig = async (companyId) => {
    isNetworkError.value = false;

    try {
      const response = await fetchCompanyNotificationsConfiguration(companyId);

      if (response) {
        response.forEach((config) => {
          if (config.id === VIA_EMAIL) {
            notificationsConfig.value.find((currentConfig) => currentConfig.id === VIA_EMAIL).value = config.value;
          }

          if (config.id === VIA_WHATSAPP) {
            notificationsConfig.value.find((currentConfig) => currentConfig.id === VIA_WHATSAPP).value = config.value;
          }
        })
      }
    } catch (e) {
      isNetworkError.value = true;
    }
  };

  return {
    notificationsConfig,
    isNetworkError,

    successSetCompanyConfig,
    isSendingSetCompanyConfig,
    getCompanyConfig,
    setCompanyConfig,
  };
}
