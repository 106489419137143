import {
  companyNotificationsServiceUrl,
} from '@/config/config';
import axios from 'axios';
import environment from "../../../environment";

export const VIA_EMAIL = "via_email";
export const VIA_WHATSAPP = "via_whatsapp";
export const EMAIL_CHANNEL = "EMAIL";
export const WHATSAPP_CHANNEL = "WHATSAPP";

export const fetchCompanyNotificationsConfiguration = async (
  companyId
) => {
  const url = new URL(companyNotificationsServiceUrl(companyId));

  url.search = new URLSearchParams({
    tenantId: environment.tenantId,
  }).toString();

  const raw = await axios.get(url.toString());

  if(!raw.data) {
    return null;
  }

  return [
    ...raw.data.channels.map((channel) => {
      return {
        id: (channel === EMAIL_CHANNEL) ? VIA_EMAIL : VIA_WHATSAPP,
        value: channel === EMAIL_CHANNEL || channel === WHATSAPP_CHANNEL,
      };
    })
  ];
};

export const setCompanyNotificationsConfiguration = async (
  companyId,
  configuration
) => {
  const url = new URL(companyNotificationsServiceUrl(companyId));

  url.search = new URLSearchParams({
    tenantId: environment.tenantId,
  }).toString();

  await axios.put(url.toString(), configuration);
};
